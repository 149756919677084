<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- PAckage title and actions  -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title">A1 release</h2>

              <div class="d-flex align-items-center">
                <small>
                  <span class="text-muted text-small">Application Type: </span>
                  <strong>Application Type</strong>
                </small>
                <button class="btn btn-secondary btn-sm ml-2">Change</button>
              </div>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between">
              <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
                <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
                <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none"><use xlink:href="/icons/symbol-defs.svg#icon-delete"></use></svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/applicant">Applicant</router-link>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/charges-liens-interests">Charge, Lien or Interest</router-link>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/pid-legal-description">Description of Land</router-link>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/transferor">Transferor</router-link>
                  </div>
                  <div class="step active">
                    <router-link class="step-link" to="/pages/edit-application/execution">Execution</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Attachment for Supporting Evidence</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Validation Summary</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/electronic-instrument">View Electronic Instrument</router-link>
                  </div>
                </div>
              </div>
            </div>

            <!-- Package form entry -->
            <div class="col-md-9">
              <div class="mb-3">

                <h2>Execution</h2>
                <p class="lead">Add execution details for the officer and transferor</p>

                <a class="collapse-toggle" href="#help-text" data-toggle="collapse" aria-expanded="false" aria-controls="help-text">
                  <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                  <svg class="icon icon-close-circle"><use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use></svg>
                  When do I complete this section?
                </a>
                <div class="collapse" id="help-text">
                  <div class="card bg-light mt-1">
                    <div class="card-body">
                      <h4>This is an example of a help text collapse.</h4>
                      <p>This section may be completed after the instrument is printed and executed. For more about aligning the instrument with the true copy, see <a href="javascript:void(0)">Practice Note Truing Up an Original Electronic Instrument</a>.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="bg-medium-dark p-2 text-center">
                <h4 class="mb-0">Signature Block</h4>
              </div>

              <div class="mb-4 p-2 p-md-4 bg-medium">
                <div class="row">
                  <div class="col-md-6">
                    <h3 class="mb-1">Witnessing Officer</h3>
                    <button class="btn btn-link mb-2 pl-0">Clear All Fields</button>

                    <!-- Data entry form -->
                    <form v-if="witnessingOfficer == false">
                      <div class="custom-control custom-checkbox mb-3">
                        <input id="affidavit" type="checkbox" class="custom-control-input">
                        <label class="custom-control-label" for="affidavit">I have an Affidavit of Execution</label>
                      </div>
                      <div class="form-group">
                        <label for="name">Officer Name</label>
                        <input class="form-control" type="text" name="name" id="name" v-model="name">
                      </div>
                      <div class="form-group">
                        <label for="capacity">Professional Capacity
                          <a href="#" data-toggle="popover" data-placement="top" data-title="What is Professional Capacity?" data-content="Your professional designation is a representation that you are authorized by the Evidence Act to take affidavits for use in British Columbia." v-popover>
                            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                          </a>
                        </label>
                        <select class="form-control" id="capacity" v-model="capacity">
                          <option value="select">Select capacity</option>
                          <option value="barrister">Barrister &amp; Solicitor</option>
                          <option value="notary">Notary Public</option>
                          <option value="commisioner">Commissioner for Taking Affidavits for British Columbia</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="address">Address</label>
                        <input class="form-control mb-1" type="text" name="address" id="address" v-model="address">
                        <input class="form-control" type="text" name="address2" id="adddress2" v-model="address2">
                      </div>
                      <div class="form-group">
                        <label for="country">Country</label>
                        <select class="form-control" id="country" v-model="country">
                          <option>Canada</option>
                          <option>United Kingdom</option>
                          <option>United States</option>
                          <option>Mexico</option>
                          <option>Other</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="city">City</label>
                        <input class="form-control" type="text" name="city" id="city" v-model="city">
                      </div>
                      <div class="form-row">
                        <div class="form-group col-lg-6">
                          <label for="province">Province</label>
                          <select class="form-control" name="province" id="province" v-model="province">
                            <option value="AB">Alberta</option>
                            <option value="BC">British Columbia</option>
                            <option value="MB">Manitoba</option>
                            <option value="NB">New Brunswick</option>
                            <option value="NL">Newfoundland and Labrador</option>
                            <option value="NT">Northwest Territory</option>
                            <option value="NS">Nova Scotia</option>
                            <option value="NU">Nunavut</option>
                            <option value="ON">Ontario</option>
                            <option value="PE">Prince Edward Island</option>
                            <option value="QC">Quebec</option>
                            <option value="SK">Saskatchewan</option>
                            <option value="YT">Yukon Territory</option>
                          </select>
                        </div>
                        <div class="form-group col-lg-6">
                          <label for="postal-code">Postal Code</label>
                          <input class="form-control" name="postal-code" id="postal-code" autocomplete="shipping postal-code" v-model="postalCode">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="addition">Additional Information (optional)</label>
                        <textarea class="form-control" name="addition" id="addition" cols="30" rows="4"></textarea>
                      </div>
                      <div class="form-actions mt-1">
                        <button class="btn btn-md btn-tertiary" v-if="witnessingOfficer == false" @click.prevent="witnessingOfficer = true; witnessingOfficerAdded = true">Save Witnessing Officer</button>
                        <button class="btn btn-md btn-link"@click.prevent="witnessingOfficer = true" v-if="witnessingOfficerAdded == true">Cancel</button>
                      </div>
                    </form>

                    <!-- Witnessing officer completed -->
                    <div class="card" v-if="witnessingOfficer == true">
                      <div class="card-body">
                        <h4 class="card-title">{{ name }}</h4>
                        {{ address }}<br>
                        {{ address2 }}<br v-if="address2">
                        {{ city }} {{ province }} {{ postalCode }}<br>
                        {{ country }}<br>
                      </div>
                      <div class="card-footer">
                        <button class="btn btn-md btn-link px-0" @click="witnessingOfficer = false">Edit</button>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 mt-4 mt-md-0">
                    <h3 class="mb-3">Transferor Signature</h3>

                    <!-- Individual signatory completed -->
                    <div class="card mb-4" v-if="individualSignatoryAdded == true">
                      <div class="card-body">
                        <h4 class="card-title">Signed: {{ individualSignatoryDate }}</h4>
                        {{ individualSignatoryName }}<br>
                        {{ repCapacity }}
                        </ul>
                      </div>
                      <div class="card-footer">
                        <button class="btn btn-md btn-link pl-0" @click="individualSignatory = true; individualSignatoryAdded = false; signatureFormOpen = true">Edit</button>
                        <button class="btn btn-md btn-link text-danger">Remove</button>
                      </div>
                    </div>

                    <!-- Corporate signatory completed -->
                    <div class="card mb-4" v-if="corpSignatoryAdded == true">
                      <div class="card-body">
                        <h4 class="card-title">Signed: {{ corpSignatoryDate }}</h4>
                        {{ corpSignatoryName }}<br>
                        {{ corpRepCapacity }}
                      </div>
                      <div class="card-footer">
                        <button class="btn btn-md btn-link pl-0" @click="corpSignatory = true; corpSignatoryAdded = false; signatureFormOpen = true">Edit</button>
                        <button class="btn btn-md btn-link text-danger">Remove</button>
                      </div>
                    </div>

                    <!-- Individual signatory form -->
                    <form v-if="individualSignatory == true">
                      <h4>Add Individual Signatory</h4>
                      <div class="form-group position-relative">
                        <label for="individualDate">Date of Signing</label>
                        <input type="text" class="form-control" id="individualDate" name="individualDate" v-model="individualSignatoryDate">
                        <svg class="icon icon-inline-right icon-date position-absolute"><use xlink:href="/icons/symbol-defs.svg#icon-date"></use></svg>
                      </div>
                      <div class="form-group">
                        <label for="transferorName">Transferor Name</label>
                        <input type="text" class="form-control" id="transferorName" name="transferorName" v-model="individualSignatoryName">
                      </div>
                      <div class="custom-control custom-checkbox mb-3">
                        <input id="executionByRep" type="checkbox" class="custom-control-input" v-model="executionByRep">
                        <label class="custom-control-label" for="executionByRep">Execution by Representative</label>
                      </div>
                      <div class="form-group" v-if="executionByRep == true">
                        <label for="repCapacity">Representative Capacity
                          <a class="text-tertiary" href="#" data-toggle="popover" data-placement="top" data-title="What is Transferor Capacity?" data-content="A corporation must authorize an individual to sign on its behalf or an individual may designate another party to sign on their behalf." v-popover>
                            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                          </a>
                        </label>
                        <select class="form-control" id="repCapacity" v-model="repCapacity">
                          <option value="">Select capacity</option>
                          <option value="committee">By his/her Committee</option>
                          <option value="attorney">By his/her Attorney</option>
                          <option value="other">Other</option>
                        </select>
                      </div>

                      <div v-if="repCapacity == 'committee'">
                        <div class="form-group">
                          <label for="committeeName">Committee Name
                            <a class="text-tertiary" href="#" data-toggle="popover" data-placement="top" data-title="Who is the signatory?" data-content="Enter the name of the person executing the instrument as designated by a corporation or individual." v-popover>
                              <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                            </a>
                          </label>
                          <input type="text" class="form-control" id="committeeName" name="committeeName" v-model="committeeName">
                        </div>
                        <div class="form-group">
                          <label for="committeeFiling">Filing Number</label>
                          <input type="text" class="form-control" id="committeeFiling" name="committeeFiling" v-model="committeeFiling">
                        </div>
                        <div class="form-group">
                          <div class="custom-control custom-checkbox mb-3">
                            <input id="committeeFilingWithin" type="checkbox" class="custom-control-input" v-model="committeeFilingWithin">
                            <label class="custom-control-label" for="committeeFilingWithin">Filing Number is within package</label>
                          </div>
                        </div>
                      </div>

                      <div v-if="repCapacity == 'attorney'">
                        <label for="attorneyType">Select Type of Attorney</label>
                        <div class="form-group">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="attorneyIndividual" name="attorneyType" class="custom-control-input" v-model="attorneyType">
                            <label class="custom-control-label" for="attorneyIndividual">Individual</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="attorneyCorporate" name="attorneyType" class="custom-control-input" v-model="attorneyType">
                            <label class="custom-control-label" for="attorneyCorporate">Corporate</label>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="powerOfAttorneyNo">Power of Attorney Number</label>
                          <input type="text" class="form-control" id="powerOfAttorneyNo" name="powerOfAttorneyNo" v-model="powerOfAttorneyNo">
                        </div>
                        <div class="form-group">
                          <div class="custom-control custom-checkbox mb-3">
                            <input id="poaNoWithinPackage" type="checkbox" class="custom-control-input" v-model="poaNoWithinPackage">
                            <label class="custom-control-label" for="poaNoWithinPackage">POA Number is within the package</label>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="attorneyName1">Attorney Name 1</label>
                          <input type="text" class="form-control" id="attorneyName1" name="attorneyName1" v-model="attorneyName1">
                        </div>
                        <div class="form-group">
                          <label for="attorneyName2">Attorney Name 2 (optional)</label>
                          <input type="text" class="form-control" id="attorneyName2" name="attorneyName2" v-model="attorneyName2">
                        </div>
                      </div>

                      <div v-if="repCapacity == 'other'">
                        <div class="form-group">
                          <label for="otherRepCapacity">Enter the Representative's Capacity
                            <a class="text-tertiary" href="#" data-toggle="popover" data-placement="top" data-title="Who is the signatory?" data-content="Enter the name of the person executing the instrument as designated by a corporation or individual." v-popover>
                              <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                            </a>
                          </label>
                          <input type="text" class="form-control" id="otherRepCapacity" name="otherRepCapacity" v-model="otherRepCapacity">
                        </div>
                        <div class="form-group">
                          <label for="otherFilingNo">Filing Number</label>
                          <input type="text" class="form-control" id="otherFilingNo" name="otherFilingNo" v-model="otherFilingNo">
                        </div>
                        <div class="form-group">
                          <div class="custom-control custom-checkbox mb-3">
                            <input id="otherPoaNoWithinPackage" type="checkbox" class="custom-control-input" v-model="otherPoaNoWithinPackage">
                            <label class="custom-control-label" for="otherPoaNoWithinPackage">POA Number is within the package</label>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="otherSignatoryName">Signatory Name</label>
                          <input type="text" class="form-control" id="otherSignatoryName" name="otherSignatoryName" v-model="otherSignatoryName">
                        </div>
                      </div>

                      <div class="form-actions mt-1 mb-4">
                        <button class="btn btn-md btn-tertiary" @click.prevent="individualSignatoryAdded = true; individualSignatory = false; signatureFormOpen = false">Save Individual Signatory</button>
                        <button class="btn btn-md btn-link" @click.prevent="individualSignatory = false; signatureFormOpen = false">Cancel</button>
                      </div>
                    </form>

                    <!-- Corporate signatory form -->
                    <form v-if="corpSignatory == true">
                      <h4>Add Corporate Signatory</h4>
                      <div class="form-group position-relative">
                        <label for="corpDate">Date of Signing</label>
                        <input type="text" class="form-control" id="corpDate" name="corpDate" v-model="corpSignatoryDate">
                        <svg class="icon icon-inline-right icon-date position-absolute"><use xlink:href="/icons/symbol-defs.svg#icon-date"></use></svg>
                      </div>
                      <div class="form-group">
                        <label for="corpTransferorName">Transferor Name</label>
                        <input type="text" class="form-control" id="corpTransferorName" name="corpTransferorName" v-model="corpSignatoryName">
                      </div>
                      <div class="form-group" v-if="executionByRep == true">
                        <label for="corpRepCapacity">Signatory Capacity
                          <a class="text-tertiary" href="#" data-toggle="popover" data-placement="top" data-title="What is Transferor Capacity?" data-content="A corporation must authorize an individual to sign on its behalf or an individual may designate another party to sign on their behalf." v-popover>
                            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                          </a>
                        </label>
                        <select class="form-control" id="corpRepCapacity" v-model="corpRepCapacity">
                          <option value="">Select capacity</option>
                          <option value="authorized">By its Authorized Signatory</option>
                          <option value="attorney">By its Attorney</option>
                          <option value="other">Other</option>
                        </select>
                      </div>

                      <div v-if="corpRepCapacity == 'authorized'">
                        <div class="form-group">
                          <label for="authorizedSignatoryName1">Signatory Name 1
                            <a class="text-tertiary" href="#" data-toggle="popover" data-placement="top" data-title="Who is the signatory?" data-content="Enter the name of the person executing the instrument as designated by a corporation or individual." v-popover>
                              <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                            </a>
                          </label>
                          <input type="text" class="form-control" id="authorizedSignatoryName1" name="authorizedSignatoryName1" v-model="authorizedSignatoryName1">
                        </div>
                        <div class="form-group">
                          <label for="authorizedSignatoryName2">Signatory Name 2 (optional)</label>
                          <input type="text" class="form-control" id="authorizedSignatoryName2" name="authorizedSignatoryName2" v-model="authorizedSignatoryName2">
                        </div>
                      </div>

                      <div v-if="corpRepCapacity == 'attorney'">
                        <label for="attorneyType">Select Type of Attorney</label>
                        <div class="form-group">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="attorneyIndividual" name="corpAttorneyType" class="custom-control-input" v-model="corpAttorneyType">
                            <label class="custom-control-label" for="attorneyIndividual">Individual</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="attorneyCorporate" name="corpAttorneyType" class="custom-control-input" v-model="corpAttorneyType">
                            <label class="custom-control-label" for="attorneyCorporate">Corporate</label>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="corpAttorneyName1">Attorney Name 1
                            <a class="text-tertiary" href="#" data-toggle="popover" data-placement="top" data-title="Who is the signatory?" data-content="Enter the name of the person executing the instrument as designated by a corporation or individual." v-popover>
                              <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                            </a>
                          </label>
                          <input type="text" class="form-control" id="corpAttorneyName1" name="corpAttorneyName1" v-model="corpAttorneyName1">
                        </div>
                        <div class="form-group">
                          <label for="corpAttorneyName2">Attorney Name 2 (optional)</label>
                          <input type="text" class="form-control" id="corpAttorneyName2" name="corpAttorneyName2" v-model="corpAttorneyName2">
                        </div>
                      </div>

                      <div v-if="corpRepCapacity == 'other'">
                        <div class="form-group">
                          <label for="otherRepCapacity">Enter the Signatory Capacity</label>
                          <input type="text" class="form-control" id="otherRepCapacity" name="otherRepCapacity" v-model="otherRepCapacity">
                        </div>
                        <div class="form-group">
                          <label for="corpSignatoryName1">Signatory Name 1</label>
                          <input type="text" class="form-control" id="corpSignatoryName1" name="corpSignatoryName1" v-model="corpSignatoryName1">
                        </div>
                        <div class="form-group">
                          <label for="corpSignatoryName2">Signatory Name 2 (optional)</label>
                          <input type="text" class="form-control" id="corpSignatoryName2" name="corpSignatoryName2" v-model="corpSignatoryName2">
                        </div>
                      </div>

                      <div class="form-actions mt-1 mb-4">
                        <button class="btn btn-md btn-tertiary" @click.prevent="corpSignatoryAdded = true; corpSignatory = false; signatureFormOpen = false">Save Corporate Signatory</button>
                        <button class="btn btn-md btn-link" @click.prevent="corpSignatory = false; signatureFormOpen = false">Cancel</button>
                      </div>
                    </form>

                    <!-- Transferor signature actions -->
                    <div v-if="signatureFormOpen == false">
                      <button class="btn btn-md btn-tertiary" @click="individualSignatory = true; signatureFormOpen = true">+ Add Individual Signatory</button>
                      <div class="form-text">Name of party signing.</div>

                      <button class="btn btn-md btn-tertiary mt-3" @click="corpSignatory = true; signatureFormOpen = true">+ Add Corporate Signature</button>
                      <div class="form-text">Name of party signing for corporation.</div>
                    </div>

                  </div>
                </div>
              </div>

              <button class="btn btn-md btn-tertiary">+ Add Signature Block</button>
              <div class="form-text mb-4">If more than two parties signing, click Add more signatures to attach a Schedule D</div>

              <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">Continue <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>

import PackageHeader from '../../../components/PackageHeader.vue'
import PackageNavigation from '../../../components/PackageNavigation.vue'
import HelpPanel from '../../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
      witnessingOfficer: false,
      witnessingOfficerAdded: false,
      individualSignatory: false,
      individualSignatoryAdded: false,
      corpSignatory: false,
      corpSignatoryAdded: false,
      signatureFormOpen: false,
      name: '',
      capacity: '',
      address: '',
      address2: '',
      city: '',
      province: '',
      postalCode: '',
      country: '',
      moreInfo: '',
      individualSignatoryName: '',
      individualSignatoryDate: '',
      individualSignatoryCapacity: '',
      executionByRep: false,
      repCapacity: '',
      committeeName: '',
      committeeFiling: '',
      committeeFilingWithin: false,
      attorneyType: '',
      powerOfAttorneyNo: '',
      poaNoWithinPackage: false,
      attorneyName1: '',
      attorneyName2: '',
      otherRepCapacity: '',
      otherFilingNo: '',
      otherPoaNoWithinPackage: false,
      otherSignatoryName: '',
      corpSignatoryName: '',
      corpSignatoryDate: '',
      corpSignatoryCapacity: '',
      corpRepCapacity: '',
      authorizedSignatoryName1: '',
      authorizedSignatoryName2: '',
      corpAttorneyType: '',
      corpAttorneyName1: '',
      corpAttorneyName2: '',
      corpSignatoryName1: '',
      corpSignatoryName1: ''
    }
  },
  methods: {

  }
}
</script>

